import { Grid, Link } from "@mui/material";
import Title from "../../shared/Title/Title";
import './GetStarted.css'
import SubTitle from "../../shared/SubTitle/SubTitle";
import ios from "../../../assets/icons/ios_app.svg";
import android from "../../../assets/icons/android_app.svg";
import React from "react";
import { useHistory } from "react-router-dom";

const GetStarted = () => {

    const history = useHistory();

    const [state, setState] = React.useState({
        email: ''
    })

    const [saved, setSaved] = React.useState(false);

    const handleChange = (
        e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
    ) => {
        setState({
            ...state,
            [e.target.name]: e.target.value.trim(),
        });
    }

    return (
        <Grid container textAlign='center' id='getStarted'>
            <Grid item xs={12} textAlign='center' >
                <Grid container textAlign='center' className='getBox'>
                    <Grid item xs={12}>
                        <Title content="Get started with Xward Digital app" />
                    </Grid>
                    <Grid item xs={12}>
                        <SubTitle content="Download Xward Digital app to get the most sophisticated banking experience" />
                    </Grid>
                    <Grid item xs={12} className="startedApps">
                        <Grid container spacing={2}>
                            <Grid item xs={6} md={6} className="startedAGridRight">
                                <Link onClick={() => history.push('/coming-soon')} ><img src={ios} className="downloadImage" alt="Xward iOs app" /></Link>
                            </Grid>
                            <Grid item xs={6} md={6} className="startedAGridLeft">
                                <Link onClick={() => history.push('/coming-soon')} ><img src={android} className="downloadImage" alt="Xward android app" /></Link>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );

}

export default GetStarted;