import { Typography } from "@mui/material";
import { useEffect } from "react";
import './Terms.css';

const Terms = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div id="terms">
            <div className="termsBox">
                <Typography className="title">TERMS AND CONDITIONS</Typography>
                <Typography className="subtitle">BACKGROUND</Typography>
                These Terms and Conditions, together with all other documents referred to herein, set out the terms of use under which you may use our website, www.xwardpay.io. Please read these Terms and Conditions carefully and ensure that you understand them. Your agreement to comply with and be bound by these Terms and Conditions is deemed to occur upon your first use of our website. If you do not agree to comply with and be bound by these Terms and Conditions, you must stop using our website immediately.
                <Typography className="subtitle">Information About Us</Typography>
                Our website is owned and operated by XWARD PAY INC (“we/us/our”), a company incorporated in Canada and registered under company number BC1332850.<br /><br />
                Registered address: 422 Richards St, Suite 170, Vancouver, Bc, Canada V6b2z4<br />
                Business Address: 422 Richards St, Suite 170, Vancouver, Bc, Canada V6b2z4
                <Typography className="subtitle">Access to Our Website</Typography>
                Access to our website is free of charge.
                It is your responsibility to make any and all arrangements necessary in order to access our website.<br /><br />
                Access to our website is provided “as is” and on an “as available” basis.  We may alter, suspend or discontinue our website (or any part of it) at any time and without notice. We will not be liable to you in any way if our website (or any part of them) is unavailable at any time and for any period.<br /><br />
                <Typography className="subtitle">Intellectual Property Rights</Typography>
                All content (which includes any and all text, images, photographs, audio, video, scripts, code, software, databases and any other form of information capable of being stored on a computer) included on our website and the copyright and other intellectual property rights subsisting in that content, unless specifically labeled otherwise, belongs exclusively to or has been licensed by us. All content is protected by applicable Canadian and international intellectual property laws and treaties.<br/><br/>
                You may not reproduce, copy, publish, modify, distribute, sell, rent, sub-licence, store, or in any other manner re-use or exploit content from our website unless given express written permission to do so by us.<br/><br/>
                You may:
                <ul>
                    <li>
                    Access, view and use our website in a web browser (including any web browsing capability built into other types of software or app)
                    </li>
                    <li>
                    Print pages from our website
                    </li>
                    <li>
                    Download extracts from pages on our website
                    </li>
                    <li>
                    Save pages from our website for later and/or offline viewing
                    </li>
                </ul>
                Our status as the owner and author of the content on our website (or that of identified licensors, as appropriate) must always be acknowledged.<br /><br />
                You may not use any content saved or downloaded from our website for commercial purposes.
                <Typography className="subtitle">Links to Our Website</Typography>
                Framing or embedding of our website on other website is not permitted.
                <Typography className="subtitle">Links to Other Sites</Typography>
                Links to other sites may be included on our website. Unless expressly stated, these sites are not under our control. We neither assume nor accept responsibility or liability for the information, data, content, products or services of third-party sites. The inclusion of a link to another site on our website is for information only and does not imply any endorsement or recommendation of the sites themselves or of those in control of them.
                <Typography className="subtitle">Use of Our System</Typography>
                You may use any online communications facility that we make available on our website either now or in the future, to contact us. This may include, but is not limited to, contact forms, email and live chat. However, you must not:
                <ul>
                    <li>
                    Communicate in a way that is obscene, deliberately offensive, hateful or otherwise inflammatory
                    </li>
                    <li>
                    Submit information that promotes violence
                    </li>
                    <li>
                    Submit information that promotes or assists in any form of unlawful activity
                    </li>
                    <li>
                    Submit information that discriminates against, or is in any way defamatory of, any person, group or class of persons, race, sex, religion, nationality, disability, sexual orientation or age
                    </li>
                    <li>
                    Submit information that is intended or is otherwise likely to threaten, harass, annoy, alarm, inconvenience, upset, or embarrass another person;
                    </li>
                    <li>
                    Submit information that is fraudulent or is otherwise likely to deceive
                    </li>
                    <li>
                    Submit information that is intended or otherwise likely to infringe (or threaten to infringe) another person’s right to privacy or otherwise uses their personal data in a way that you do not have a right to
                    </li>
                    <li>
                    Misleadingly impersonate any person or otherwise misrepresent your identity or affiliation in a way that is calculated to deceive
                    </li>
                    <li>
                    Imply any form of affiliation with us where none exists
                    </li>
                    <li>
                    Infringe, or assist in the infringement of, the intellectual property rights (including, but not limited to, copyright, trademarks and database rights) of any other party
                    </li>
                    <li>
                    Submit information in breach of any legal duty owed to a third-party including, but not limited to, contractual duties and duties of confidence
                    </li>
                    <li>
                    Any personal information sent to us (including but not limited to your name and contact details), will be collected, used and held in accordance with your rights and our obligations under the Data Protection laws in force from time to time. Further information is set out in our Privacy Policy, contained herein.
                    </li>
                </ul>
                You acknowledge and agree that you are solely responsible for the accuracy and content of any information you provide to us. You acknowledge and agree that we may disclose and transfer your personal information to our affiliates, to any other person or entity with your consent or if we are compelled to disclose such information by law.
                <Typography className="subtitle">Monitoring</Typography>
                We may monitor use of our website from time to time, but we have no obligation to do so. If we monitor your use of our website, we will do so in accordance with applicable laws.
                <Typography className="subtitle">Acceptable Use Policy</Typography>
                You may only use our website in a manner that is lawful, and you must ensure that you comply fully with any and all local, national or international laws and/or regulations.  In using our website, you must not:
                <ul>
                    <li>
                    Transmit or otherwise make available any material that:
                    <ul>
                        <li>
                        Is obscene, deliberately offensive, hateful or otherwise inflammatory
                        </li>
                        <li>
                        Promotes violence
                        </li>
                        <li>
                        Is intended or is otherwise likely to threaten, harass, annoy, alarm, inconvenience, upset, or embarrass another person
                        </li>
                        <li>
                        Discriminates against, or is in any way defamatory of, any person, group or class of persons, race, sex, religion, nationality, disability, sexual orientation or age
                        </li>
                        <li>
                        Is fraudulent or is otherwise likely to deceive another person
                        </li>
                        <li>
                        Is intended or is otherwise likely to infringe (or to threaten to infringe) another person’s privacy
                        </li>
                    </ul>
                    </li>
                    <li>
                    Transmit or otherwise make available any material which infringes, or assists in the infringement of, the intellectual property rights (including, but not limited to, copyright, trademarks and database rights) of any other party
                    </li>
                    <li>
                    Knowingly send, upload, or in any other way transmit data that contains any form of virus or other malware, or any other code designed to adversely affect computer hardware, software, or data of any kind
                    </li>
                    <li>
                    Breach any legal duty owed to a third party including, but not limited to, contractual duties and duties of confidence
                    </li>
                    <li>
                    Use our website for any fraudulent or unlawful purpose
                    </li>
                    <li>
                    Use our website for commercial purposes
                    </li>
                </ul>
                We reserve the right to suspend or terminate your access to our website if you materially breach the provisions of these Terms and Conditions.  Specifically, we may take one or more of the following actions:
                <ul>
                    <li>
                    Suspend, whether temporarily or permanently, your right to access our website
                    </li>
                    <li>
                    Issue you with a written warning
                    </li>
                    <li>
                    Take legal proceedings against you for reimbursement of any and all relevant costs on an indemnity basis resulting from your breach
                    </li>
                    <li>
                    Take further legal action against you as appropriate
                    </li>
                    <li>
                    Disclose such information to law enforcement authorities as required or as we deem reasonably necessary
                    </li>
                    <li>
                    Any other actions which we deem reasonably appropriate (and lawful)
                    </li>
                </ul>
                We hereby exclude any and all liability arising out of any actions (including, but not limited to those set out above) that we may take in response to breaches of these Terms and Conditions.
                <Typography className="subtitle">Legal Disclaimers</Typography>
                Our website and the content available on them are for informational purposes only. Nothing on our website constitutes professional or legal advice. It is provided for general information purposes only and you accept responsibility for your use of the information provided.<br /><br />
                Insofar as is permitted by law, we make no representation, warranty, or guarantee that our website will meet your requirements, that it will not infringe the rights of third parties, that it will be compatible with all software and hardware, or that it will be secure.<br /><br />
                We make reasonable efforts to ensure that the content on our website is complete, accurate, and up to date. We do not, however, make any representations, warranties or guarantees (whether express or implied) that the content is complete, accurate, or up to date. We are not responsible for any errors or omissions, or for the results obtained from the use of information on our website. We do not make any warranties as to performance or fitness for a particular purpose.<br /><br />
                In no event will we, or our directors, employees or agents, be liable to you or anyone else for any decision made or action taken in reliance on the information provided on these website or for any consequential, special or similar damages.<br /><br />
                We make no representation, warranty, or guarantee that services shown on our website will be available from us.  Please contact us if you wish to enquire as to the availability of any services.
                <Typography className="subtitle">Our Liability</Typography>
                To the fullest extent permissible by applicable law, we accept no liability to any user for any loss or damage, including any indirect, incidental, special, consequential or punitive damages, whether foreseeable or otherwise, in contract, tort (including negligence), for breach of statutory duty, or otherwise, arising out of or in connection with the use of (or inability to use) our website or the use of or reliance upon any content included on our website.<br /><br />
                To the fullest extent permissible by law, we exclude all representations, warranties, and guarantees (whether express or implied) that may apply to our website or any content included on our website.<br /><br />
                We accept no liability for loss of profits, sales, business or revenue, loss of business opportunity, goodwill or reputation, loss of anticipated savings, business interruption, or for any indirect or consequential loss or damage.<br /><br />
                We exercise all reasonable skill and care to ensure that our website is free from viruses and other malware.  You acknowledge and accept that we have no liability for any loss or damage resulting from a virus or other malware, a distributed denial of service attack, or other harmful material or event that may adversely affect your hardware, software, data or other material that occurs as a result of your use of our website (including the downloading of any content from them) or any other site referred to on our website.<br /><br />
                We neither assume nor accept responsibility or liability arising out of any disruption or non-availability of our website resulting from external causes including, but not limited to, ISP equipment failure, host equipment failure, communications network failure, natural events, acts of war, or legal restrictions and censorship.<br /><br />
                Nothing in these Terms and Conditions excludes or restricts our liability for fraud or fraudulent misrepresentation, for death or personal injury resulting from negligence, or for any other forms of liability which cannot be excluded or restricted by law.
                <Typography className="subtitle">Indemnification</Typography>
                To the fullest extent permitted by applicable law, you agree to defend, indemnify and hold XWARD PAY, its affiliates, directors, employees and agents harmless from and against any claims, liabilities, proceedings, fines, damages, losses, demands and costs and expenses incurred by us relating to or arising from any content or information transmitted by you using our website or otherwise arising from your use of our website or the actions of a third-party who gained access to our website because of your action or inaction, or your breach of these Terms and Conditions.
                <Typography className="subtitle">Viruses, Malware and Security</Typography>
                You are responsible for protecting your hardware, software, data and other material from viruses, malware, and other internet security risks.<br /><br />
                You must not deliberately introduce viruses or other malware, or any other material which is malicious or technologically harmful either to or via our website.<br /><br />
                You must not attempt to gain unauthorized access to any part of our website, the server on which our website is stored, or any other server, computer, or database connected to our website.<br /><br />
                You must not attack our website by means of a denial of service attack, a distributed denial of service attack, or by any other means.
                <Typography className="subtitle">Privacy and Cookies</Typography>
                Use of our website is also governed by our Privacy & Cookie Policies contained herein. Our Privacy Policy and Cookie Policy are incorporated into these Terms and Conditions by reference.
                <Typography className="subtitle">Data Protection</Typography>
                All personal information that we may use will be collected, processed, and held in accordance with the provisions of the Applicable law.<br /><br />
                For full details of our collection, processing, storage, and retention of personal data including, but not limited to, the purpose(s) for which personal data is used, the legal basis or bases for using it, details of your rights and how to exercise them, and personal data sharing (where applicable), please refer to our Privacy Policy contained herein.
                <Typography className="subtitle">Changes to these Terms and Conditions</Typography>
                We may alter these Terms and Conditions at any time.  Any such changes will become binding on you upon your first use of our website after the changes have been implemented.  You are therefore advised to check this page from time to time.<br /><br />
                In the event of any conflict between the current version of these Terms and Conditions and any previous version(s), the provisions current and in effect shall prevail unless it is expressly stated otherwise.
                <Typography className="subtitle">Law and Jurisdiction</Typography>
                These Terms and Conditions, and the relationship between you and us (whether contractual or otherwise) shall be governed by and construed in accordance with the laws of Canada.<br /><br />
                Any disputes concerning these Terms and Conditions, the relationship between you and us, or any matters arising therefrom or associated therewith (whether contractual or otherwise) shall be subject to the exclusive jurisdiction of the courts of Canada.
               
                <br /><br />
                <br /><br />
                This Privacy Policy was last updated 10th March 2022.
            </div>
        </div>
    );
};

export default Terms;
