import { Card, CardContent, Grid, Typography } from "@mui/material";
import { Faq } from "../../../services/slices/faq/faq.types";
import CircleIcon from '@mui/icons-material/Circle';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

interface Props {
    faq: Faq,
    onClick: () => void
}

const FAQCard = ({ faq, onClick }: Props) => {
    return (
        <Card onClick={onClick} id='faqItem'>
            <CardContent>
                <Grid container className="content" alignItems='center'>
                    <Grid item >
                        <CircleIcon className="circleIcon" sx={{color: '#F8E0EE', height: '72px', width: '72px', marginRight: '1rem'}} />
                    </Grid>
                    <Grid item >
                        <Typography className="faqName">{faq.name}</Typography>
                    </Grid>
                    <Grid item style={{ marginLeft: 'auto' }} >
                        <ArrowForwardIosIcon className="chervonIcon" />
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
}

export default FAQCard;