import { Grid } from "@mui/material";
import './Header.css'
import logo from "../../assets/images/xward_logo.svg";

const Header = () => {
    return (
        <Grid container id='header' className="header">
            <Grid item xs={12}  >
                <img src={logo} alt="XWard Pay" className="logo" />
            </Grid>
        </Grid>
    );
}

export default Header;