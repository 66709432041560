import { Box, Grid } from '@mui/material';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import './App.css';
import Coming from './components/Coming/Coming';
import Cookies from './components/Cookies/Cookies';
import FAQ from './components/FAQ/FAQ';
import Footer from './components/Footer/Footer';
import Landing from './components/Landing/Landing';
import Navbar from './components/Navbar/Navbar';
import Plans from './components/Plans/Plans';
import Privacy from './components/Privacy/Privacy';
import Terms from './components/Terms/Terms';
import axios from 'axios';
import { useEffect, useState } from 'react';

const App = () => {
  
  const [state, setState] = useState({
    ip: "",
    countryName: "",
    countryCode: "",
    city: "",
    timezone: ""
  });

  const getGeoInfo = () => {
    axios
      .get("https://ipapi.co/json/")
      .then((response) => {
        let data = response.data;
        setState({
          ...state,
          ip: data.ip,
          countryName: data.country_name,
          countryCode: data.country_calling_code,
          city: data.city,
          timezone: data.timezone
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getGeoInfo();
  }, []);
  

  return (
    <div className="App">
    {
    state.countryCode === '+44' ? (
      <Box textAlign="center" pt={12}>Service not available in you country</Box>
    ) : (
      <Router>
        <Switch>
          <Route exact path="/privacy-policy">
            <Navbar />
            <Privacy />
            <Footer />
          </Route>
          <Route exact path="/terms-of-use">
            <Navbar />
            <Terms />
            <Footer />
          </Route>
          <Route exact path="/cookies-policy">
            <Navbar />
            <Cookies />
            <Footer />
          </Route>
          <Route exact path="/coming-soon">
            <Coming />
          </Route>
          <Route exact path="/plans">
            <Navbar />
            <Plans />
            <Footer />
          </Route>
          <Route exact path="/help">
            <Navbar />
            <FAQ />
            <Footer />
          </Route>
          <Route exact path="/press">
            <Coming />
          </Route>
          <Route exact path="/about">
            <Coming />
          </Route>
          <Route exact path="/">
            <Navbar />
            <Landing />
            <Footer />
          </Route>
        </Switch>
      </Router>
      )
      }
    </div>
  );
}

export default App;
