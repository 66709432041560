import { Collapse, Divider, Grid, Link, List, ListItemButton, ListItemText } from "@mui/material";
import './Footer.css';
import logo from '../../assets/images/xward_logo.svg';
import ios from '../../assets/icons/ios_app.svg';
import android from '../../assets/icons/android_app.svg';
import { useState } from "react";
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { useHistory } from "react-router-dom";

const Footer = () => {

    const history = useHistory();

    return (
        <div id='footer'>
            <Grid container className='footerContent' spacing={{ md: 2, sm: 1 }}>
                <Grid item xs={12} md={2} sm={12} textAlign={{ xs: 'start' }}>
                    <img src={logo} alt="Xward" className="logo" />
                </Grid>
                <Grid item xs={12} md={2} sm={3} className='links desktop tablet' textAlign={{ xs: 'start' }}>
                    <p className="header">Sitemap</p>
                    <Link onClick={() => history.push('/')}>Home</Link>
                    <Link onClick={() => history.push('/plans')}>Plans</Link>
                    <Link onClick={() => history.push('/coming-soon')}>About</Link>
                    <Link onClick={() => history.push('/coming-soon')}>Help</Link>
                    <Link onClick={() => history.push('/coming-soon')}>Press</Link>
                </Grid>
                <Grid item xs={12} md={2} sm={3} className='links desktop tablet' textAlign={{ xs: 'start' }}>
                    <p className="header">Social Media</p>
                    <Link onClick={() => history.push('/coming-soon')}>Facebook</Link>
                    <Link onClick={() => history.push('/coming-soon')}>Instagram</Link>
                    <Link onClick={() => history.push('/coming-soon')}>LinkedIn</Link>
                    <Link onClick={() => history.push('/coming-soon')}>Twitter</Link>
                    <Link onClick={() => history.push('/coming-soon')}>YouTube</Link>
                </Grid>
                <Grid item xs={12} md={2} sm={3} className='links desktop tablet' textAlign={{ xs: 'start' }}>
                    <p className="header">Policies</p>
                    <Link onClick={() => history.push('/privacy-policy')}>Privacy Policy</Link>
                    <Link onClick={() => history.push('/terms-of-use')}>Terms of Use</Link>
                    <Link onClick={() => history.push('/cookies-policy')}>Cookies Policy</Link>
                </Grid>
                <Grid item xs={12} className='links mobile'>
                    <MobileListLink />
                </Grid>

                <Grid item xs={12} md={4} sm={3} className='apps' textAlign={{ md: 'end', xs: 'start' }} >
                    <Grid container spacing={{ xs: 2 }}>
                        <Grid item md={12} sm={12}>
                            <p className="header">Get our free app</p>
                        </Grid>
                        <Grid item md={12} sm={12} className='icons'>
                            <Link onClick={() => history.push('/coming-soon')}><img src={ios} alt="Xward iOs app" /></Link>
                            <Link onClick={() => history.push('/coming-soon')}><img src={android} alt="Xward android app" /></Link>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12} md={12} sm={12} textAlign={{ md: 'end', xs: 'start', sm: 'start' }} >
                    <p className="copyright">© Xward Digital</p>
                </Grid>
            </Grid>
        </div>
    );
}


const MobileListLink = () => {

    const history = useHistory();
    const [openSitemap, setOpenSitemap] = useState(false);
    const [openSocialMedia, setOpenSocialMedia] = useState(false);
    const [openPolicies, setOpenPolicies] = useState(false);

    const handleSitemap = () => {
        setOpenSocialMedia(false);
        setOpenPolicies(false);
        setOpenSitemap(!openSitemap);
    };

    const handleSocialMedia = () => {
        setOpenSitemap(false);
        setOpenPolicies(false);
        setOpenSocialMedia(!openSocialMedia);
    };

    const handlePolicies = () => {
        setOpenSocialMedia(false);
        setOpenSitemap(false);
        setOpenPolicies(!openPolicies);
    };

    return (
        <>
            <List>
                <ListItemButton className='button' onClick={handleSitemap}>
                    <ListItemText className='text' primary="Sitemap" />
                    {openSitemap ? <ExpandLess style={{ fill: "#D1D3D4" }} /> : <ExpandMore style={{ fill: "#D1D3D4" }} />}
                </ListItemButton>
                <Collapse in={openSitemap} timeout="auto" unmountOnExit className='collapsedLinks'>
                    <Link onClick={() => history.push('/')}>Home</Link>
                    <Link onClick={() => history.push('/plans')}>Plans</Link>
                    <Link onClick={() => history.push('/coming-soon')}>About</Link>
                    <Link onClick={() => history.push('/coming-soon')}>Help</Link>
                    <Link onClick={() => history.push('/coming-soon')}>Press</Link>
                </Collapse>
                <ListItemButton className='button' onClick={handleSocialMedia}>
                    <ListItemText className='text' primary="Social Media" />
                    {openSocialMedia ? <ExpandLess style={{ fill: "#D1D3D4" }} /> : <ExpandMore style={{ fill: "#D1D3D4" }} />}
                </ListItemButton>
                <Collapse in={openSocialMedia} timeout="auto" unmountOnExit className='collapsedLinks'>
                    <Link onClick={() => history.push('/coming-soon')}>Facebook</Link>
                    <Link onClick={() => history.push('/coming-soon')}>Instagram</Link>
                    <Link onClick={() => history.push('/coming-soon')}>LinkedIn</Link>
                    <Link onClick={() => history.push('/coming-soon')}>Twitter</Link>
                    <Link onClick={() => history.push('/coming-soon')}>YouTube</Link>
                </Collapse>
                <ListItemButton className='button' onClick={handlePolicies}>
                    <ListItemText className='text' primary="Policies" />
                    {openPolicies ? <ExpandLess style={{ fill: "#D1D3D4" }} /> : <ExpandMore style={{ fill: "#D1D3D4" }} />}
                </ListItemButton>
                <Collapse in={openPolicies} timeout="auto" unmountOnExit className='collapsedLinks'>
                    <Link onClick={() => history.push('/privacy-policy')}>Privacy Policy</Link>
                    <Link onClick={() => history.push('/terms-of-use')}>Terms of Use</Link>
                    <Link onClick={() => history.push('/cookies-policy')}>Cookies Policy</Link>
                </Collapse>
            </List>
            <Divider className='divider' />
        </>
    );
}

export default Footer;