import { IconButton, InputBase, Paper } from "@mui/material";

interface InputProps {
  name: string,
  title: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
}

const InputDialog = ({ name, title, value, onChange }: InputProps) => {

  return (
    <Paper
      component="form"
      sx={{ width: '100%', height: '56px', background: '#2a2e32', border: '1px solid #2a2e32', boxSizing: 'border-box', borderRadius: '8px', margin: '0px', marginTop: '2rem', marginBottom: '2rem' }}
    >
      <InputBase
        placeholder={title}
        name={name}
        value={value}
        onChange={onChange}
        sx={{ width: '95%' }}
        inputProps={{ style: { fontSize: '16px', lineHeight: '150%', color: '#F8F9F9', background: 'transparent', paddingTop: '1rem', paddingLeft: '1rem' } }}
      />
    </Paper>
  );
};

export default InputDialog;
