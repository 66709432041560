import { Card, CardActions, CardContent, CardHeader, Divider, Grid, List, ListItem, ListItemIcon } from "@mui/material";
import PrimaryButton from "../../shared/Buttons/PrimaryButton";
import SubTitle from "../../shared/SubTitle/SubTitle";
import Title from "../../shared/Title/Title";
import './OurPlans.css';
import icon from '../../../assets/icons/plan_icon.png';
import { useHistory } from "react-router-dom";

const OurPlans = () => {

    const plans = [
        {
            title: 'Basic',
            price: '$ 5,99/month',
            description: [
                'Simple and free registration',
                'Free top up',
                'Cashback for online purchases'
            ],
            button: 'Get Plan'
        },
        {
            title: 'Easy Money',
            price: '$ 8,99/month',
            description: [
                'Up to 20% of cashback',
                'Payment for shopping services without a fee',
                'High credit limit'
            ],
            button: 'Get Plan'
        },
        {
            title: 'Eco Card',
            price: '$ 12,99/month',
            description: [
                '5% of the cashback - to save the ocean',
                'Individual card design',
                '60 days of grace period'
            ],
            button: 'Get Plan'
        }
    ]


    return (
        <Grid container id='ourPlans'>
            <Grid item xs={12} md={6} sm={10}>
                <Title content="Our Plans" />
            </Grid>
            <Grid item xs={12} md={6} sm={10}>
                <SubTitle content="Choose the best benefits for yourself" />
            </Grid>
            <Grid container className='plans' spacing={3}>
                {plans.map((plan) => (
                    <PlanCard key={plan.title} title={plan.title} price={plan.price} description={plan.description} buttonTitle={plan.button} />
                ))}
            </Grid>
        </Grid>
    );
}

interface Props {
    title: string,
    price: string,
    description: Array<string>,
    buttonTitle: string
}

const PlanCard = ({ title, price, description, buttonTitle }: Props) => {
    const history = useHistory();

    return (
        <Grid item md={4} xs={12} sm={4}>
            <Card className='planCard' style={{ background: 'rgba(59, 58, 66, 0.35)' }} >
                <CardHeader className='header' title={title} subheader={price} />
                <Divider className="divider" style={{ background: '#F8F9F9' }} />
                <CardContent className="content">
                    <List>
                        {description.map((el, index) => (
                            <ListItem key={index}>
                                <ListItemIcon className='planListIcon'><img src={icon} alt="Xward"/></ListItemIcon>
                                {el}
                            </ListItem>
                        ))}
                    </List>
                </CardContent>
                <CardActions className='actions'>
                    <PrimaryButton onClick={() => history.push('/coming-soon')} className='button'><span className="text">{buttonTitle}</span></PrimaryButton>
                </CardActions>
            </Card>
        </Grid>
    );
}

export default OurPlans;