import { Box, Grid } from "@mui/material";
import './Download.css';
import hand from "../../../assets/images/card.png";
import Title from "../../shared/Title/Title";
import SubTitle from "../../shared/SubTitle/SubTitle";
import { useHistory } from "react-router-dom";
import OutlinedButton from "../../shared/Buttons/OutlinedButton";

const Download = () => {
    const history = useHistory();

    return (
        <div id="download">
            <Grid container className="downloadGrid">
                <Grid item xs={12} md={6} sm={12} textAlign={{ xs: 'end' }}>
                    <Grid container className='downloadText' >
                        <Grid item textAlign={{ xs: 'start' }}>
                            <Box className="downloadSpacer">
                                <Title content="The future of crypto payments begins now" />
                            </Box>
                            <Box className="downloadSpacer">
                                <SubTitle content="Xward Digital is an all in-one-solution for all your crypto needs. Buy and sell crypto fast with our secure app, and use the card to pay for goods and services." />
                            </Box>
                            <Box className="downloadSpacer">
                                <OutlinedButton onClick={() => history.push('/coming-soon')} className="button"><span className="text">Get Started</span></OutlinedButton>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={6} sm={12} textAlign="right">
                    <img src={hand} alt="Xward" className="hand" />
                </Grid>
            </Grid>
        </div >
    );
}

export default Download;