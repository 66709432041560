import { AppBar, Drawer, Grid, IconButton, Link, List, ListItem, ListItemText, Toolbar } from '@mui/material';
import logo from "../../assets/images/xward_logo.svg";
import './Navbar.css';
import CloseIcon from '@mui/icons-material/Close';
import MenuIcon from '@mui/icons-material/Menu';
import { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import PrimaryButton from '../shared/Buttons/PrimaryButton';
import SecondaryButton from '../shared/Buttons/SecondaryButton';
import OutlinedButton from '../shared/Buttons/OutlinedButton';
import TextButton from '../shared/Buttons/TextButton';

const Navbar = () => {

    const [isActive] = useState<boolean>(false);
    const [open, setOpen] = useState<boolean>(false);
    const history = useHistory();
    const location = useLocation();

    const handleDrawerToggle = () => {
        setOpen(true);
    };

    return (
        <AppBar id='navbar' style={{ background: '#1F1C25', boxShadow: '4px #ffffff' }}>
            <Grid container spacing={4}
                className="desktop tablet main">
                <Grid item className='logo' md={2}>
                    <Link href='/'><img src={logo} alt='logo' className="logoImage" /></Link>
                </Grid>
                <Grid item className='menu' md={6}>
                    <Link className={`navbarItem ${location.pathname === '/' ? 'active' : ''}`} onClick={() => history.push('/')}>Home
                        <div className='radial'></div>
                    </Link>
                    <Link className={`navbarItem ${location.pathname === '/plans' ? 'active' : ''}`} onClick={() => history.push('/plans')}>Plans
                        <div className='radial'></div>
                    </Link>
                    <Link className={`navbarItem ${location.pathname === '/about' ? 'active' : ''}`} onClick={() => history.push('/about')}>About
                        <div className='radial'></div>
                    </Link>
                    <Link className={`navbarItem ${location.pathname === '/help' ? 'active' : ''}`} onClick={() => history.push('/help')}>Help
                        <div className='radial'></div>
                    </Link>
                    <Link className={`navbarItem ${location.pathname === '/press' ? 'active' : ''}`} onClick={() => history.push('/press')}>Press
                        <div className='radial'></div>
                    </Link>
                </Grid>
                <Grid item md={4}>
                    <TextButton onClick={() => history.push('/coming-soon')} className="button"><span className="text">Login</span></TextButton>
                    <OutlinedButton onClick={() => history.push('/coming-soon')} className="button"><span className="text">Get Started</span></OutlinedButton>
                </Grid>
            </Grid>

            <Grid container className='mobile'>
                <Grid item xs={6}>
                    <Link href='/'><img src={logo} className="logoImage" alt='logo' /></Link>
                </Grid>
                <Grid item xs={6} textAlign='end'>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        className="drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                    >
                        <MenuIcon style={{ color: '#ffffff' }}/>
                    </IconButton>
                    <MobileDrawer open={open} setOpen={setOpen} />
                </Grid>
            </Grid>
        </AppBar>
    );
}


interface Props {
    open: boolean;
    setOpen: (value: boolean) => void;
}

const MobileDrawer = ({ open, setOpen }: Props) => {
    const history = useHistory();
    const toggleDrawer = () => {
        setOpen(!open);
    };

    return (
        <Drawer open={open} anchor='right' id="mobileDrawer">
            <Toolbar
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                    px: [1],
                }}
            >
                <IconButton onClick={toggleDrawer}>
                    <CloseIcon style={{ color: '#ffffff' }} />
                </IconButton>
            </Toolbar>
            <List>
                <ListItem onClick={() => {history.push('/');toggleDrawer()}} className='listButton' button >
                    <ListItemText primary="Home" />
                </ListItem>
                <ListItem onClick={() => {history.push('/plans');toggleDrawer()}} className='listButton' button >
                    <ListItemText primary="Plans" />
                </ListItem>
                <ListItem onClick={() => {history.push('/about');toggleDrawer()}} className='listButton' button >
                    <ListItemText primary="About" />
                </ListItem>
                <ListItem onClick={() => {history.push('/help');toggleDrawer()}} className='listButton' button >
                    <ListItemText primary="Help" />
                </ListItem>
                <ListItem onClick={() => {history.push('/press');toggleDrawer()}} className='listButton' button >
                    <ListItemText primary="Press" />
                </ListItem>
            </List>
        </Drawer >
    );

}

export default Navbar;