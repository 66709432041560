import { Grid } from "@mui/material";
import Header from "../Header/Header";
import Download from "./Download/Download";
import GetStarted from "./GetStarted/GetStarted";
import OurPlans from "./OurPlans/OurPlans";
import SaveTheOcean from "./SaveTheOcean/SaveTheOcean";
import Crypto from "./Crypto/Crypto";

const Landing = () => {
    return (
        <Grid container>
            <Grid item className="main">
                <Header />
            </Grid>
            <Download />
            <Grid item className="main">
                <Crypto />
                <SaveTheOcean />
                <OurPlans />
                <GetStarted />
            </Grid>
        </Grid>
    );
}

export default Landing;