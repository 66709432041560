import { Typography } from "@mui/material";
import './SubTitleGreen.css'

interface Props {
    content: string;
}

const SubTitleGreen = ({content}: Props) => {

    return (
        <Typography component='h6' id='subTitleGreen' className="sectionSubTitleGreen">{content}</Typography>
    );
}

export default SubTitleGreen;