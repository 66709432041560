import { Box, Grid, Typography } from "@mui/material";
import { useEffect } from "react";
import './Privacy.css';

const Privacy = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div id="privacy">
            <div className="privacyBox">
                <Typography id="privacy" className="title">Privacy Policy</Typography>
                XWARD DIGITAL needs to collect and use certain types of information about the physical and legal persons that wish to participate in XWARD DIGITAL services.<br /><br />
                The personal information must be collected and dealt with appropriately in accordance with the company AML Policy and in accordance with this Privacy Policy drawn up in accordance with the GDPR and International standards.<br /><br />
                In accordance with the provisions of GDPR and International standards, any User who provides his information under the present policies confirms that he understands and accepts the reason for the gathering of information and consents to the processing of information for the purposes of money laundering prevention.<br /><br />
                Any User is entitled to know who is responsible for the processing of his or her personal data.Any data collected during the course of XWARD DIGITAL business whether it is collected on paper, stored in a computer database, or recorded on other material is subject to this policy and is protected under the applicable law and the GDPR provisions regarding data control and processing.
                <Typography id="privacy" className="subtitle">What Does This Policy Cover?</Typography>
                This Privacy Policy applies only to your use of our website. Our website may contain links to other websites. Please note that we have no control over how your data is collected, stored, or used by other websites and we advise you to check the privacy policies of any such websites before providing any data to them.
                <Typography id="privacy" className="subtitle">What is Personal Data?</Typography>
                Personal data is defined by the General Data Protection Regulation (EU Regulation 2016/679) (the “GDPR”) as ‘any information relating to an identifiable person who can be directly or indirectly identified in particular by reference to an identifier’.<br /><br />
                Personal data is, in simpler terms, any information about you that enables you to be identified. Personal data covers information such as your name and contact details, but it also covers less obvious information such as identification numbers, electronic location data, and other online identifiers.
                <Typography id="privacy" className="subtitle">What Data Do We Collect?</Typography>
                XWARD DIGITAL collects personal information in the course of its business with you when you apply to start or continue to operate a business relationship with XWARD DIGITAL. The information is collected with your consent, or as permitted or required by law.<br /><br />
                Depending upon your use of our website and Application, the personal information that you provide or that XWARD DIGITAL asks for and collect could include the following:
                <ul>
                    <li>
                        identities
                    </li>
                    <li>
                        contact information
                    </li>
                    <li>
                        birth dates
                    </li>
                    <li>
                        financial background
                    </li>
                    <li>
                        billing information
                    </li>
                    <li>
                        personal identifiers
                    </li>
                    <li>
                        information collected for prevention of  frauds, money laundering, and other illegal activities, and for due diligence purposes
                    </li>
                    <li>
                        Website Usage Related Data: we use cookies to collect certain website usage related data, including IP address, web browser type and version and operating system. This data is anonymous and cannot be used to identify any individual user of our website
                    </li>
                </ul>
                This information may be collected by way of paper documents, scanned or faxed copies, telephone, emails, verbal interviews, face to face interactions, or data received through our online website or Application. This information will include the information collected by XWARD DIGITAL about yourself, and any other individual with whom you deal through XWARD DIGITAL, which include counterparties or senders or beneficiaries of funds or services.<br/><br/>
                We collect this information through our employees, affiliates, and online resources, government agencies, or business partners, or consumer reporting agencies, or may be provided by yourself upfront when you approach XWARD DIGITAL for applying to our services.<br/><br/>
                XWARD DIGITAL uses personal information in the course of its business that is foreign exchange, money transfer, payment and related services and products, for the following purposes:
                <ul>
                    <li>
                    To process your transactions
                    </li>
                    <li>
                    To collect amounts payable to us
                    </li>
                    <li>
                    To effectuate the terms and conditions of your ongoing agreement with us
                    </li>
                    <li>
                    To meet legal and regulatory obligations, risk management requirements, to facilitate fraud and money-laundering prevention, and for ensuring security
                    </li>
                    <li>
                    For marketing and promotional purposes, unless you “opt out” or withdraw consent to this specific purpose, as described in the section entitled “Opting Out” below
                    </li>
                    <li>
                    Record keeping, or reporting requirements, as required by law or by statutory agencies
                    </li>
                    <li>
                    To meet audit and insurance purposes
                    </li>
                    <li>
                    To enable our agents, correspondents, or business partners to process or give effect to your transactions or to meet their legal or regulatory obligations
                    </li>
                    <li>
                    Otherwise with your consent for any purpose
                    </li>
                </ul>
                <Typography id="privacy" className="subtitle">How Do We Use Your Personal Data?</Typography>
                Under the Applicable law, we must always have a lawful basis for using personal data. This may be because the data is necessary for our provision of services to you and the performance of a contract with you, because you have consented to our use of your personal data, or because it is in our legitimate business interests to use it. Your personal data may be used for one of the following purposes:
                <ul>
                    <li>
                    Providing and managing your access to our website
                    </li>
                    <li>
                    Supplying our products and services to you
                    </li>
                    <li>
                    Communicating with you
                    </li>
                    <li>
                    Supplying you with marketing and other information that you have opted-in to (you may unsubscribe or opt-out at any time by clicking on the “unsubscribe” link located on our emails or by contacting us at contact@xwardpay.io
                    </li>
                    <li>
                    Maintaining our own accounts and records
                    </li>
                    <li>
                    Complying with legal and regulatory obligations on our and your behalf
                    </li>
                    <li>
                    Analyzing your use of our website to enable us to continually improve it and your user experience
                    </li>
                </ul>
                <Typography id="privacy" className="subtitle">Option Out Right</Typography>
                XWARD DIGITAL may use your contact details and information related to your service preferences for marketing and solicitation purposes to send you offers or promotions of XWARD DIGITAL or affiliates. You may “opt out” or withdraw consent to disclosure of information for the purposes described in this section at any time by contacting us at contact@xwardpay.io
                <Typography id="privacy" className="subtitle">How Long Will We Keep Your Personal Data?</Typography>
                We will keep your personal data for such period as is required for us to provide our services to you, or such longer period as we may be required to process the data to ensure our compliance with all relevant laws and regulatory obligations to which we are subject
                <Typography id="privacy" className="subtitle">How and Where Do We Store Your Personal Data?</Typography>
                Personal data is processed both manually and electronically and may be held in a number of different places in line with the operations of a typical business. The locations your personal data could be stored include, but are not limited to: 
                <ul>
                    <li>
                    Our databases and CRM
                    </li>
                    <li>
                    Emails on computers
                    </li>
                    <li>
                    Mobile phones and tablets
                    </li>
                    <li>
                    Contact address books
                    </li>
                    <li>
                    Business cards
                    </li>
                    <li>
                    Notebooks
                    </li>
                    <li>
                    Electronic documents on our file servers
                    </li>
                    <li>
                    Paper files in our offices
                    </li>
                    <li>
                    Cloud back-up and storage
                    </li>
                </ul>
                The security of your personal data is important to us, and to protect your data we use and maintain appropriate technical and organizational measures to protect your personal data and to prevent the accidental or unlawful destruction, loss, alteration, unauthorized disclosure of or access to personal data transmitted, stored or otherwise processed. We have a framework of policies, procedures and training to cover information and cyber security, confidentiality and data protection.
                <Typography id="privacy" className="subtitle">Sharing and Transfer of Personal Data</Typography>
                We will only disclose personal data to third parties as is necessary to provide our services and/or to comply with your instructions. We may share your personal data with our affiliates and subsidiaries. We do not give or sell your personal information to third parties.<br/><br/>
                In addition, we may be legally required to share certain personal data, which might include yours, if we are involved in legal proceedings or complying with legal obligations, a court order, or the instructions of a government authority.<br/><br/>
                If any of your personal data is required by a third-party, we will take steps to ensure that your personal data is handled safely, securely, and in accordance with your rights, our obligations, and the third-party’s obligations under the law.
                <Typography id="privacy" className="subtitle">Confidentiality</Typography>
                XWARD DIGITAL maintains physical, electronic, and procedural safeguards to protect personal information against loss, theft, and any form of unauthorized access. Only those who are authorized employees, agents, or representatives that require access to your personal information in order to fulfill their job requirements will have access to the information. XWARD DIGITAL’s obligations will become effective only when the information is received by XWARD DIGITAL physically or by its internal systems, but not while it is in transmission out of or to XWARD DIGITAL’s physical possession or internal systems; because data exchange over the internet or the telephone system or physical mail cannot be guaranteed to be perfectly secure, any personal information you submit to us or access electronically or over the telephone is done at your own risk, and XWARD DIGITAL does not guarantee or warrant the security of information so transmitted to XWARD DIGITAL. You should exercise caution to prevent any third parties, or parties fraudulently posing as XWARD DIGITAL, from unlawfully intercepting your transmissions or causing you to disclose personal information.
                <Typography id="privacy" className="subtitle">Consent</Typography>
                By using XWARD DIGITAL’s website or services, you signify your acceptance of our privacy policy.<br/><br/>
                This Privacy Statement provides our intentions in regard to your personal information collected by us from you. By providing us with personal information, we will assume your consent to our collection, use and disclosure of such information for the purposes described in this Privacy Policy wherever applicable. Please note that your consent implies the consent to use your email addresses, postal addresses, and other contact resources that you provide to XWARD DIGITAL to communicate with you.<br/><br/>
                Your consent may be given in various ways, such as: Orally, electronically or on a document in any form; or implied by voluntarily choosing to use our services; by not withdrawing consent to use your personal information for any identified purpose; by not using an “opt out” option provided for the specified purposes in this statement, if any. Such consent may be given by you directly or by your legal representative.<br/><br/>
                Withdrawal of your consent will be entirely at your choice at any time, and will be effective, except in cases where XWARD DIGITAL is subject to legal, contractual, regulatory, governmental, and/ or statutory obligations for record maintenance and disclosure; and except in case of transactions already initiated which need the use of your personal information for fulfillment. Note that in case of such withdrawal of consent XWARD DIGITAL may no longer be able to provide certain of our products or services which require your personal information.
                <Typography id="privacy" className="title">Your Rights</Typography>
                <ul>
                    <li>
                    The right to be informed about our collection and use of your personal data
                    </li>
                    <li>
                    The right to access the personal data we hold about you – you have the right to obtain confirmation as to whether we process personal data about you and certain information about how and why we process your personal data
                    </li>
                    <li>
                    The right to have your personal data amended or rectified if any of your personal data held by us is inaccurate or incomplete
                    </li>
                    <li>
                    The right to erasure (also known as the right to be forgotten), i.e. the right to ask us to delete or otherwise dispose of any of your personal data that we have
                    </li>
                    <li>
                    The right to restrict the processing of your personal data
                    </li>
                    <li>
                    The right to object to us using your personal data for a particular purpose or purposes
                    </li>
                    <li>
                    The right to data portability. This means that, if you have provided personal data to us directly, we are using it with your consent or for the performance of a contract or the provision of services, and that data is processed using automated means, you can ask us for a copy of that personal data to re-use with another service or business
                    </li>
                    <li>
                    Rights relating to automated decision-making and profiling. We do not use your personal data in this way.
                    </li>
                </ul>
                For more information about our use of your personal data or exercising your rights as outlined above, or in the event that you wish to make a complaint, please contact us at:<br/>
                XWARD DIGITAL INC.422 Richards St, Suite 170, Vancouver, Bc, Canada<br/>contact@xwardpay.io 

                <Typography id="privacy" className="subtitle"> Contact Us</Typography>
                To contact us about anything to do with your personal data and data protection, including to make a subject access request, please use the following details:<br/><br/>
                Responsible Person: Compliance Officer<br/>
                XWARD DIGITAL INC.422 Richards St, Suite 170, Vancouver, Bc, Canada<br/>
                contact@xwardpay.io 
                <Typography id="privacy" className="subtitle">Changes to this Privacy Policy</Typography>
                We may change this Privacy Notice from time to time. This may be necessary, for example, if the law changes, or if we change our business in a way that affects personal data protection.<br/><br/>
                Any changes will be posted on our website and you will be deemed to have accepted the terms of the Privacy Policy on your first use of our website following the alterations.<br/><br/>
                This Privacy Policy was last updated 10th March 2022.
            </div>
        </div>
    );
};

export default Privacy;
