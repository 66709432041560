import { Button as MuiButton, ButtonProps } from '@mui/material';
import { FC } from 'react';
import './Buttons.css';

const OutlinedButton: FC<ButtonProps> = (props) => {
    return (
        <MuiButton id='outlinedBtn' variant='contained' {...props}>
            {props.children}
        </MuiButton>
    );
}

export default OutlinedButton;