import { Grid } from "@mui/material";
import PrimaryButton from "../../shared/Buttons/PrimaryButton";
import Title from "../../shared/Title/Title";
import './SaveTheOcean.css'
import image from '../../../assets/images/save_ocean.png';
import SubTitle from "../../shared/SubTitle/SubTitle";
import SubTitleGreen from "../../shared/SubTitleGreen/SubTitleGreen";

const SaveTheOcean = () => {

    return (
        <>
            <Grid container textAlign='center' id='saveTheOcean'>
                <Grid item xs={12} md={6} sm={10}>
                    <SubTitleGreen content="Our Mission" />
                </Grid>
                <Grid item xs={12} md={10} sm={10}>
                    <Title content="Empowering people with crypto" />
                </Grid>
                <Grid item xs={12} md={8} sm={10}>
                    <SubTitle content="Cryptocurrencies empower people with a safe, reliable, and decentralized payment solution. At Xward Digital, we’re on a mission to make this power accessible to as many people as possible. " />
                </Grid>
            </Grid>
        </>
    );
}

export default SaveTheOcean;