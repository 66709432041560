import { Grid } from "@mui/material";
import './Coming.css';
import logo from '../../assets/images/xward_logo.svg';
import InputDialog from "../shared/Inputs/input.dialog";
import PrimaryButton from "../shared/Buttons/PrimaryButton";
import React from "react";
import SubTitleComing from "../shared/SubTitleComing/SubTitleComing";
import Title from "../shared/Title/Title";
import { Link, useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import { send, init } from "emailjs-com"

const Coming = () => {

    const history = useHistory();
    
    const [state, setState] = React.useState({
        email: ''
    })

    const handleChange = (
        e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
    ) => {
        setState({
            ...state,
            [e.target.name]: e.target.value.trim(),
        });
    }

    const SERVICE_ID = "service_5ev3sb9";
    const TEMPLATE_ID = "template_be644be";
    const USER_ID = "user_fYyTt9B5aXVLOyGVpuHaJ";

    const submitWhitelist = () => {
        init(USER_ID);
        const toSend = state;
        send(SERVICE_ID, TEMPLATE_ID, toSend)
            .then((res) => {
                Swal.fire(
                    'Your message sent',
                    'We will contact you as soon as possible',
                    'success'
                )
                setState({ email: '' });
            })
            .catch((err) => {
                Swal.fire(
                    'Ooops',
                    'Something went wrong. Please try again',
                    'error'
                )
            });
    }

    return (
        <div id='coming'>
            <Grid container className="top">
                <Grid item xs={12}  >
                    <Link to="/"><img src={logo} alt="XWard Pay" className="logo" /></Link>
                </Grid>
            </Grid>
            <Grid container className="title">
                <Grid item xs={12}  >
                    <Title content="Coming soon" />
                </Grid>
                <Grid item xs={12}  >
                    <SubTitleComing content="Xward Digital is currently working hard on this page!" />
                </Grid>
            </Grid>
            <Grid container spacing={1}>
                <Grid item xs={12} md={2} >
                </Grid>
                <Grid item xs={12} md={5} className="textGrid">
                    <InputDialog name="email" title="Enter your Email" value={state.email} onChange={handleChange} />
                </Grid>
                <Grid item xs={12} md={3} className="buttonGrid">
                    <PrimaryButton className="button" onClick={submitWhitelist}><span className="text">Join Whitelist</span></PrimaryButton>
                </Grid>
                <Grid item xs={12} md={2} >
                </Grid>
            </Grid>
        </div>
    );
}


export default Coming;