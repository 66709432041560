import { Button, CircularProgress, Grid, Typography } from '@mui/material';
import { SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { RootState } from '../../services/rootReducer';
import { getFaqs } from '../../services/slices/faq/faq.slice';
import { Faq, FaqList } from '../../services/slices/faq/faq.types';
import PrimaryButton from '../shared/Buttons/PrimaryButton';
import FAQCard from './components/card.faq';
import FAQList from './components/list.faq';
import SearchInput from './components/search.input';
import './FAQ.css';

const FAQ = () => {

    const dispatch = useDispatch();
    const { i18n } = useTranslation();
    const [searchValue, setSearchValue] = useState<string>('');
    const [showDetails, setShowDetails] = useState<boolean>(false);
    const [filteredFaqs, setFilteredFaqs] = useState<Faq[]>([]);
    const [selectedHeader, setSelectedHeader] = useState<string>('');
    const history = useHistory();

    const { list } = useSelector(
        (state: RootState) => state.faq
    );

    useEffect(() => {
        const fetch = async () => {
            try {
                await dispatch(getFaqs(i18n.language));
            } catch (e) {
                console.log(e);
            }
        };
        fetch();
    }, [i18n.language, dispatch]);

    const handleSearchChange = (e: { target: { value: SetStateAction<string>; }; }) => {
        setSearchValue(e.target.value);
        if (searchValue.length >= 3) {
            return search();
        } else {
            setFilteredFaqs([]);
            setSelectedHeader('');
            setShowDetails(false);
        }
    }

    const handleOpenFaqItem = (item: Faq) => {
        setFilteredFaqs(list);
        setSelectedHeader(item.name)
        setShowDetails(true);
    }

    const search = () => {
        if (searchValue === '') {
            return;
        }
        const faq: Faq[] = [];
        let initList = list;

        initList.filter(el => {
            const faqList: FaqList[] = [];
            el.faqs.filter(item => {
                if (item.question.toLowerCase().includes(searchValue.toLowerCase())) {
                    faqList.push(item);
                }
            });
            if (faqList.length > 0) {
                faq.push({ name: el.name, faqs: faqList });
            }
        });

        setFilteredFaqs(faq);
        setSelectedHeader(faq[0].name);
        setShowDetails(true);
    };

    const handleClearSearch = () => {
        setSearchValue('');
        setFilteredFaqs([]);
        setSelectedHeader('');
        setShowDetails(false);
    }

    return (
        <div id='faq'>
            <Grid container id='search'>
                <Grid item className='grid' textAlign='center' alignItems='center'>
                    <Typography className='title' sx={{ paddingBottom: '2rem' }}>How can we help you?</Typography>
                    <SearchInput value={searchValue} onChange={handleSearchChange} clear={handleClearSearch} />
                </Grid>
            </Grid>
            <Grid container id='cards' className='main' spacing={2}>
                <Grid item md={12} sm={12} xs={12} sx={{ paddingBottom: '2rem' }}>
                    <Typography className='title'>FAQ</Typography>
                </Grid>
                {list.length === 0 && <CircularProgress color="inherit" sx={{ marginLeft: 'auto', marginRight: 'auto' }} />}
                {!showDetails && list.length > 0 && list.map(faq => (
                    <Grid item md={6} xs={12} sm={6} className='card' key={faq.name}>
                        <FAQCard faq={faq} onClick={() => handleOpenFaqItem(faq)} />
                    </Grid>
                ))}
                {showDetails && filteredFaqs.length > 0 && <FAQList list={filteredFaqs} selectedHeader={selectedHeader} />}
                {showDetails && filteredFaqs.length === 0 &&
                    <Grid item md={6} xs={12} sm={8} sx={{ marginLeft: 'auto', marginRight: 'auto' }}>
                        <Typography textAlign='center' className='title'>Unfortunately support material for this issue is not ready yet. Please submit a support ticket below.</Typography>
                    </Grid>
                }
            </Grid>

            <Grid container id='submitRequest' className='main' justifyContent='center'>
                <Grid item className='grid' textAlign='center' alignItems='center' md={6} sm={8} xs={12}>
                    <Typography className='title' sx={{ paddingBottom: '2rem' }}>Can't find what you're looking for?</Typography>
                    <Typography className='subtitle' sx={{ paddingBottom: '2rem' }}>Need to make a complaint, report a fraud, or just ask a question? Find out where you can get some help from a real person.</Typography>
                    <PrimaryButton onClick={() => history.push('/coming-soon')}><span>Submit a Request</span></PrimaryButton>
                </Grid>
            </Grid>
        </div>
    );
}

export default FAQ;