import { Box, Grid } from "@mui/material";
import './Crypto.css';
import crypto from './Crypto.json';

const Crypto = () => {

    return (
        <>
            <Grid container textAlign='center' id='crypto'>
                {crypto.data.map((item, index) => (
                    <Grid item md={2.4} xs={6}>
                        <Grid container className={`cell ${crypto.data[index + 1] ? null : 'last'}`}>
                            <Grid item xs={12} className='logo'>
                                <img src={item.imageName} height="20px" />
                            </Grid>
                            <Grid item xs={12} className='name'>
                                {item.name}
                            </Grid>
                            <Grid item xs={12} className='pair'>
                                <Grid container>
                                    <Grid item xs={6} className='pairText'>
                                        {item.pair}
                                    </Grid>
                                    <Grid item xs={6} alignSelf="center">
                                        <Box className={`pairChange ${parseFloat(item["24hChange"]) <= 0 ? 'red' : null}`}>
                                            {item["24hChange"]}
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} className='price'>
                                {item.price.toFixed(2)}
                            </Grid>
                            <Grid item xs={12} className='priceChange'>
                                {item["24hPrice"].toFixed(2)}
                            </Grid>
                        </Grid>
                    </Grid>
                ))}
            </Grid>
        </>
    );
}

export default Crypto;