import { Box, Grid, Hidden, Typography } from "@mui/material";
import { useEffect } from "react";
import PrimaryButton from "../shared/Buttons/PrimaryButton";
import './Plans.css';
import data from "./Plans.json";
import { useHistory } from "react-router-dom";
import included from "../../assets/images/plansIncluded.svg";
import excluded from "../../assets/images/plansExcluded.svg";

const Plans = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <>
            <Box sx={{ display: { xs: 'none', sm: 'block', md: 'block' } }}>
                <Grid container id="Plans" >
                    <Grid item sm={3} className="headerRowTop">
                        <Typography className="title">{data.title}</Typography>
                    </Grid>
                    <Grid item sm={3} className="headerRowTop">
                        <Header title={data.basic.title} price={data.basic.price} />
                    </Grid>
                    <Grid item sm={3} className="headerRowSelectedTop">
                        <Header title={data.easy.title} price={data.easy.price} />
                    </Grid>
                    <Grid item sm={3} className="headerRowTop">
                        <Header title={data.eco.title} price={data.eco.price} />
                    </Grid>
                    {data.plans.map((item, index) => (
                        <>
                            <Grid item sm={3}>
                                <TextItem title={item.title} text={item.text} />
                            </Grid>
                            <Grid item sm={3}>
                                <Item type={data.basic.items[index].type} text={data.basic.items[index].value} />
                            </Grid>
                            <Grid item sm={3} className="dataRowSelected">
                                <Item type={data.easy.items[index].type} text={data.easy.items[index].value} />
                            </Grid>
                            <Grid item sm={3}>
                                <Item type={data.eco.items[index].type} text={data.eco.items[index].value} />
                            </Grid>
                        </>
                    ))}
                    <Grid item sm={3} className="headerRowBottom">
                    </Grid>
                    <Grid item sm={3} className="headerRowBottom">
                        <Header title={data.basic.title} price={data.basic.price} />
                    </Grid>
                    <Grid item sm={3} className="headerRowSelectedBottom">
                        <Header title={data.basic.title} price={data.basic.price} />
                    </Grid>
                    <Grid item sm={3} className="headerRowBottom">
                        <Header title={data.basic.title} price={data.basic.price} />
                    </Grid>
                </Grid>
            </Box>

            <Box sx={{ display: { xs: 'block', sm: 'none', md: 'none' } }}>
                <Grid container id="Plans" >
                    <Grid item xs={12}>
                        <Typography className="title">{data.title}</Typography>
                    </Grid>
                    <Grid item xs={4} className="headerRowTop">
                        <Header title={data.basic.title} price={data.basic.price} />
                    </Grid>
                    <Grid item xs={4} className="headerRowSelectedTop">
                        <Header title={data.easy.title} price={data.easy.price} />
                    </Grid>
                    <Grid item xs={4} className="headerRowTop">
                        <Header title={data.eco.title} price={data.eco.price} />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography className="mobile">Mobile banking needs</Typography>
                    </Grid>
                    {data.plans.map((item, index) => (
                        <>
                            <Grid item xs={12} className="textRowTop">
                                <TextItem title={item.title} text={item.text} />
                            </Grid>
                            <Grid item xs={4}>
                                <Item type={data.basic.items[index].type} text={data.basic.items[index].value} />
                            </Grid>
                            <Grid item xs={4} className="dataRowSelected">
                                <Item type={data.easy.items[index].type} text={data.easy.items[index].value} />
                            </Grid>
                            <Grid item xs={4}>
                                <Item type={data.eco.items[index].type} text={data.eco.items[index].value} />
                            </Grid>
                        </>
                    ))}
                </Grid>
            </Box>
        </>
    );
};

interface Header { title: string, price: string }
const Header = ({ title, price }: Header) => {
    const history = useHistory();
    return (
        <Box textAlign="center">
            <Typography className="smallTitle">{title}</Typography>
            <Typography className="price">{price}</Typography>
            <PrimaryButton onClick={() => history.push('/coming-soon')} className="button"><span className="text">Get Plan</span></PrimaryButton>
        </Box>
    );
}

interface Item { type: string, text: string }
const Item = ({ type, text }: Item) => {
    return (
        <Box component="div" m={1} className="dataRow">
            {type === "image" ? (
                <img src={text === 'x' ? excluded : included} alt="" className="rowDataImage"/>
            ) : (
                <Typography className="rowDataText">{text}</Typography>
            )}
        </Box>
    );
}

interface TextItem { title: string, text: string }
const TextItem = ({ title, text }: TextItem) => {
    return (
        <Box component="div" m={1} className="dataRowStart">
            <Typography className="rowTitle">{title}</Typography>
            <Typography className="rowText">{text}</Typography>
        </Box>
    );
}

export default Plans;
