import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import request from "../../request";
import { AppThunk } from "../../store";
import { Faq, FaqStore } from "./faq.types";

const initialState: FaqStore = {
	loading: false,
	list: [],
	error: null
};

const slice = createSlice({
	name: 'faq',
	initialState,
	reducers: {
		setFaqs(state, action: PayloadAction<Array<Faq>>) {
			state.list = action.payload;
		},
	}
});

export const { setFaqs } = slice.actions;


export const getFaqs = (language: string): AppThunk => {
	return async dispatch => {
		try {
			/*
			let response;
				response = await request.get(`/api/faq/list/${language}`);
			const { data } = response;
			dispatch(setFaqs(data));
			return data;
			*/
			return;
		} catch (e) {
			console.log(e);
		}
	};
};

export default slice.reducer;